import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import { Link } from "gatsby"

import Image from "../Image"
import DownloadButton from "../buttons/DownloadButton"
import OffscreenRight from "../OffscreenRight"

function ExpandingSlider({ slider }) {
  const [activeSlide, setActiveSlide] = useState(0)
  const [onHover, setOnHover] = useState(false)

  return (
    <div className="h-full">
      <OffscreenRight>
        <div className="relative overflow-visible ">
          <div className="flex space-x-5 min-h-[700px] xl:min-h-[773px] ">
            {slider.map((slide, i) => (
              <motion.div
                key={slide?.image?.id}
                onClick={() => setActiveSlide(i)}
                initial={{
                  width: activeSlide === i ? "100%" : "25%",
                }}
                animate={{
                  width: activeSlide === i ? "100%" : "25%",
                }}
                transition={{ duration: 0.4, ease: "easeInOut" }}
                className={`cursor-pointer relative `}
              >
                <Image
                  objectFit="cover"
                  image={
                    slide?.image ||
                    slide?.blogPost?.blogPost?.videoHero?.video?.previewImage ||
                    slide?.featuredImage?.node
                  }
                  className="h-full w-full max-h-[574px] relative hover:opacity-70 transition-opacity duration-200"
                  onMouseEnter={() =>
                    setOnHover(activeSlide === i ? true : false)
                  }
                  onMouseLeave={() => setOnHover(false)}
                />
                <AnimatePresence>
                  {activeSlide === i && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        duration: 0.4,
                        ease: "easeInOut",
                        delay: 0.3,
                      }}
                      className="absolute top-[32%] left-[46%] pointer-events-none"
                    >
                      <Link to={slide?.uri}>
                        <svg
                          width="74"
                          height="80"
                          viewBox="0 0 74 80"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.44508 1.29354L4.44508 1.29355L4.44931 1.29587L70.9967 37.897C70.9969 37.897 70.997 37.8971 70.9971 37.8972C71.3619 38.0981 71.666 38.3932 71.8779 38.7517C72.0898 39.1103 72.2016 39.5192 72.2016 39.9358C72.2016 40.3523 72.0898 40.7612 71.8779 41.1199C71.666 41.4783 71.3619 41.7734 70.9971 41.9743C70.997 41.9744 70.9969 41.9745 70.9967 41.9746L4.44931 78.5756L4.44892 78.5759C4.10528 78.7651 3.71939 78.8643 3.32712 78.8644H3.32472C2.91156 78.8656 2.50595 78.7538 2.15173 78.5412L2.14511 78.5373C1.79682 78.3319 1.50805 78.0392 1.30726 77.6883C1.10657 77.3375 1.00069 76.9405 1.00004 76.5363V3.33445H1.00005L1.00002 3.32984C0.99817 2.92773 1.10053 2.53199 1.29713 2.18121C1.49334 1.83113 1.77673 1.53775 2.11977 1.32953C2.4698 1.12036 2.86866 1.00682 3.2764 1.0003C3.68495 0.993761 4.08802 1.0949 4.44508 1.29354Z"
                            fill={` ${onHover ? "none" : "white"}`}
                            stroke="white"
                            strokeWidth="2"
                          />
                        </svg>
                      </Link>
                    </motion.div>
                  )}
                </AnimatePresence>

                <AnimatePresence>
                  <motion.div className="mt-10">
                    {activeSlide === i && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                          duration: 0.4,
                          ease: "easeInOut",
                          delay: 0.3,
                        }}
                        key={`heading${i}`}
                        className=" max-w-[586px]"
                      >
                        {slide?.uri ? (
                          <Link to={slide?.uri}>
                            <div className="text-28 leading-[1.269] font-extrabold uppercase mb-5">
                              {slide?.heading || slide?.title}
                            </div>
                          </Link>
                        ) : (
                          <div className="text-28 leading-[1.269] font-extrabold uppercase mb-5">
                            {slide?.heading || slide?.title}
                          </div>
                        )}

                        {slide?.downloadLabel && (
                          <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                              duration: 0.6,
                              ease: "easeInOut",
                              delay: 0.4,
                            }}
                          >
                            <DownloadButton
                              downloadText={slide?.downloadLabel}
                            />
                          </motion.div>
                        )}
                      </motion.div>
                    )}
                  </motion.div>
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </div>
      </OffscreenRight>
    </div>
  )
}

export default ExpandingSlider
